<template>
  <div class="page-disclosure">
    <item-title :title="{name: '产品披露', en_1: 'ONLINE', en_2: 'INSURANCE', en_3: 'PRODUCTS', size: 'big'}" />
    <div class="dis-culture">
      <table>
        <tr class="hd">
          <th>销售名称</th>
          <th>保险公司</th>
          <th>条款信息</th>
        </tr>
        <tr v-for="item in listData" :key="item.id">
          <td>{{item.name}}</td>
          <td>{{item.bear_company}}</td>
          <td><a style="color: #3f48cc; text-decoration: underline;" @click="showDetail(item.file_address)" href="javascript:;">查看详情</a></td>
        </tr>
      </table>
      <div v-if="params.limit < total && total != 0" @click="loadMore" class="fd">展示更多内容 +</div>
      <div v-else class="fd"></div>
      <!-- <div class="url">公司互联网保险业务信息披露详见中保协官网，网址为: <span>http://icid.iachina.cn/?columnid_url=201509301401</span></div>
      <div class="cz">
        <div class="left">操作说明：</div>
        <div class="right">
          <div>1、在搜索框搜索“明亚保险经纪” </div>
          <div>2、点击搜索结果查看详情</div>
        </div>
      </div> -->
    </div>
    <div @click.stop="isShowModal = false" v-if="isShowModal" class="modal">
      <div @click.stop="isShowModal = true" class="content">
        <div class="list">
          <div class="item-title item">条款明细</div>
          <template v-if="modalData && modalData.length > 0">
            <div v-for="(item, index) in modalData" :key="index" class="item item-name">
              <a @click="preview(item.url)" href="javascript:;">{{item.name}}</a>
            </div>
          </template>
          <div v-else class="item item-name">暂无条款</div>
        </div>
        <div @click.stop="isShowModal = false" class="close-modal">
          <img src="@/assets/img/common/common_add_white.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
import { getProductList } from '@/api/disclosure'
export default {
  components: {
    ItemTitle
  },
  data() {
    return {
      params: {
        page: 1,
        limit: 15
      },
      listData: [],
      total: 0,
      isShowModal: false,
      modalData: []
    }
  },
  mounted() {
    this.$emit('loading', false)
  },
  created () {
    this.getData()
  },
  methods: {
    preview (url) {
      window.open(url)
    },
    showDetail (data) {
      // console.log(JSON.parse(data))
      this.modalData = JSON.parse(data)
      this.isShowModal = true
    },
    loadMore () {
      this.params.limit = this.params.limit + 15
      this.getData()
    },
    async getData () {
      const res = await getProductList(this.params)
      this.listData = res.records
      this.total = res.total
      // console.log(res)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-disclosure {
  table {
    margin-top: 0.3rem;
    width: 100%;
    border-top-left-radius: 0.16rem;
    border-top-right-radius: 0.16rem;
    overflow: hidden;
    tr {
      border: 1px solid #ebebeb;
      td {
        height: 0.8rem;
        padding: 0 0.08rem;
        border-right: 1px solid #ebebeb;
        text-align: center;
        font-size: .18rem;
        a {
          text-decoration: none;
          color: #333333;
        }
      }
      th {
        border-right: 1px solid #ebebeb;
        padding: 0 0.08rem;
        font-weight: 400;
        font-size: .2rem;
      }
    }
    .hd {
      background-color: #fe9022;
      height: 0.7rem;
      line-height: 0.7rem;
      text-align: center;
      width: 100%;
      th {
        border: 0;
        text-align: center;
        color: #ffffff;
        position: relative;
        padding: 0 0.04rem;
        line-height: 1.4;
        &::after {
          content: "";
          width: 1px;
          height: 0.22rem;
          background-color: #ffffff;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        text-align: center;
        &:nth-of-type(1) {
          width: 35%;
        }
        &:nth-of-type(2) {
          width: 35%;
        }
        &:last-child {
          width: 30%;
          &::after {
            content: "";
            width: 1px;
            height: 0.22rem;
            background-color: #ffffff;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: none;
          }
        }
        // &:nth-of-type(4) {
        //   width: 8%;
        // }
        // &:nth-of-type(5) {
        //   width: 10%;
        // }
        // &:nth-of-type(6) {
        //   width: 10%;
        // }
        // &:nth-of-type(7) {
        //   width: 10%;
        // }
        // &:nth-of-type(8) {
        //   width: 14%;
        //   &::after {
        //     width: 0;
        //   }
        // }
      }
    }
  }
  .cz {
    display: flex;
    padding-top: 0.2rem;
    padding-bottom: 0.3rem;
    .right {
      font-size: 0.16rem;
      color: #666666;
      div {
        &:last-child {
          padding-top: 0.1rem;
        }
      }
    }
    .left {
      font-size: 0.16rem;
      color: #666666;
    }
  }
  .url {
    font-size: 0.16rem;
    color: #666666;
    span {
      color: #1269c2;
    }
  }
  .fd {
    cursor: pointer;
    width: 100%;
    height: 0.44rem;
    background-color: #fe9022;
    border-bottom-left-radius: 0.16rem;
    border-bottom-right-radius: 0.16rem;
    font-size: 0.14rem;
    color: #ffffff;
    text-align: center;
    line-height: 0.44rem;
    margin-bottom: 0.3rem;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .6);
  .content {
    background-color: #ffffff;
    padding: .35rem;
    width: 30%;
    position: relative;
    .close-modal {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: .82rem;
      height: .82rem;
      background-color:#fe6917;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -1.5rem;
      cursor: pointer;
      img {
        width: 45%;
        height: 45%;
        transform: rotate(45deg);
      }
    }
    .list {
      text-align: center;
      border: 1px solid#fe9022;
      .item {
        border-bottom: 1px solid #fe9022;
        &.item-title {
          padding: .1rem;
          color: #fe9022;
          font-weight: bold;
          font-size: .2rem;
        }
        &.item-name {
          padding: .06rem;
          font-size: .16rem;
          a {
            color: #333333;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &:last-child {
          border: 0 none;
        }
      }
    }
  }
}
@media only screen and (max-width: $moble_width) {
  .modal .content {
    width: 80%;
  }
  .page-disclosure {  
    // table {
    //   .hd th {
    //     &:nth-of-type(2),
    //     &:nth-of-type(4),
    //     &:nth-of-type(5),
    //     &:nth-of-type(6),
    //     &:nth-of-type(7),
    //     &:nth-of-type(8) {
    //       display: none;
    //     }
    //   }
    //   tr td {
    //     &:nth-of-type(2),
    //     &:nth-of-type(4),
    //     &:nth-of-type(5),
    //     &:nth-of-type(6),
    //     &:nth-of-type(7),
    //     &:nth-of-type(8) {
    //       display: none;
    //     }
    //   }
    // }
  }
}
</style>
